import React from 'react'
import MediaQuery from "react-responsive";
import portrait01 from '../assets/images/portrait/2020/01.jpg'
import portrait02 from '../assets/images/portrait/2020/02.jpg'
import portrait03 from '../assets/images/portrait/2020/03.jpg'
import thumb01 from '../assets/images/portrait/2020/01.jpg'
import thumb02 from '../assets/images/portrait/2020/02.jpg'
import thumb03 from '../assets/images/portrait/2020/03.jpg'
import pcthumb01 from '../assets/images/portrait/2020/thumbnail/01.jpg'
import pcthumb02 from '../assets/images/portrait/2020/thumbnail/02.jpg'
import pcthumb03 from '../assets/images/portrait/2020/thumbnail/03.jpg'
import Gallery from '../components/Gallery'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Worksnav from '../components/Worksnav'


const siteTitle = 'Onodera Ryo | portrait';

var DEFAULT_IMAGES = [
  {
    id: '1',
    source: portrait01,
    thumbnail: pcthumb01,
    caption: 'Photo 1',
    description: 'Kosuke Yokota',
  },
  {
    id: '2',
    source: portrait02,
    thumbnail: pcthumb02,
    caption: 'Photo 2',
    description: 'Kaito Yoshimura for ADVENTURE KING',
  },
  {
    id: '3',
    source: portrait03,
    thumbnail: pcthumb03,
    caption: 'Photo 3',
    description: 'Kyuichiro Nakayama',
  },
]
var DEFAULT_IMAGES2 = [
  {
    id: '1',
    source: portrait01,
    thumbnail: thumb01,
    caption: 'Photo 1',
    description: 'Kosuke Yokota',
  },
  {
    id: '2',
    source: portrait02,
    thumbnail: thumb02,
    caption: 'Photo 2',
    description: 'Kaito Yoshimura for ADVENTURE KING',
  },
  {
    id: '3',
    source: portrait03,
    thumbnail: thumb03,
    caption: 'Photo 3',
    description: 'Kyuichiro Nakayama',
  },
]

const Portrait2020 = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      nonClass: '',
    }
  }
  toggleNon = () => {
    console.log("111");
    this.setState({
      nonClass: 'is-active',
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.toggleNon();
    },700);
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
        </Helmet>
        <div id="main">
          <div className="portrait">
            <Worksnav>
              Portrait 2020
            </Worksnav>
            <div class={`gall ${this.state.nonClass}`}>
            <MediaQuery query="(min-width: 767px)">
              <Gallery
                      images={DEFAULT_IMAGES.map(
                        ({ id, source, thumbnail, caption, description }) => ({
                          source,
                          thumbnail,
                          caption,
                          description,
                        })
                      )}
              />
            </MediaQuery>
            <MediaQuery query="(max-width: 767px)">
              <Gallery
                      images={DEFAULT_IMAGES2.map(
                        ({ id, source, thumbnail, caption, description }) => ({
                          source,
                          thumbnail,
                          caption,
                          description,
                        })
                      )}
              />
            </MediaQuery>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Portrait2020